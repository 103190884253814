'use client'

import { forwardRef } from 'react'
import clsx from 'clsx'

import FormInput, { FormInputProps, FormInputRef } from '../FormInput'

export interface FormInputIconProps extends FormInputProps {
    iconRight?: React.ReactNode
}

const FormInputIcon = forwardRef(
    (
        { iconRight, className, ...props }: FormInputIconProps,
        ref: FormInputRef
    ) => {
        return (
            <div className='relative'>
                <FormInput
                    {...props}
                    ref={ref}
                    className={clsx(className, iconRight ? 'pr-8' : '')}
                />
                <span className='absolute right-1 top-[50%] translate-y-[-50%]'>
                    {iconRight}
                </span>
            </div>
        )
    }
)

export default FormInputIcon
