import { useLocale, useTranslations } from 'next-intl'
import LocaleSwitcherSelect from '@/components/Locale/LocaleSwitcherSelect'
import { locales } from '@/lib/i18n'

export default function LocaleSwitcher() {
    const t = useTranslations('LocaleSwitcher')
    const locale = useLocale()

    return (
        <LocaleSwitcherSelect defaultValue={locale} label={t('label')}>
            {locales.map((cur) => (
                <option key={cur} value={cur}>
                    {t('locale', { locale: cur })}
                </option>
            ))}
        </LocaleSwitcherSelect>
    )
}
