'use client'

import { ChangeEvent, ReactNode, useTransition } from 'react'
import { usePathname, useRouter } from '@/lib/i18n'
import clsx from 'clsx'
import { useTranslations } from 'next-intl'

type Props = {
    children: ReactNode
    defaultValue: string
    label?: string
    className?: string
}

export default function LocaleSwitcherSelect({
    children,
    defaultValue,
    label,
    className,
}: Props) {
    const router = useRouter()
    const tCommon = useTranslations('Common')
    const [isPending, startTransition] = useTransition()
    const pathname = usePathname()

    function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
        const nextLocale = event.target.value
        startTransition(() => {
            router.replace(pathname, { locale: nextLocale })
        })
    }

    return (
        <div>
            {label && (
                <label
                    htmlFor={tCommon('language')}
                    className='block text-sm font-medium text-white'
                >
                    {label}
                </label>
            )}
            <select
                aria-label={tCommon('language')}
                className={clsx(
                    'mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                    className
                )}
                defaultValue={defaultValue}
                disabled={isPending}
                onChange={onSelectChange}
            >
                {children}
            </select>
        </div>
    )
}
