import { useContext, forwardRef } from 'react'
import { formInlineContext } from '../FormInline'
import { inputGroupContext } from '../InputGroup'
import { twMerge } from 'tailwind-merge'

export interface FormInputProps
    extends React.ComponentPropsWithoutRef<'input'> {
    formInputSize?: 'sm' | 'lg'
    rounded?: boolean
}

export type FormInputRef = React.ComponentPropsWithRef<'input'>['ref']

const FormInput = forwardRef((props: FormInputProps, ref: FormInputRef) => {
    const formInline = useContext(formInlineContext)
    const inputGroup = useContext(inputGroupContext)
    const { formInputSize, rounded, ...computedProps } = props
    return (
        <input
            {...computedProps}
            ref={ref}
            className={twMerge([
                'disabled:cursor-not-allowed disabled:bg-slate-100 dark:disabled:border-transparent dark:disabled:bg-darkmode-800/50',
                ' [&[readonly]]:cursor-text',
                'w-full rounded border-slate-200 text-sm shadow-none transition duration-200 ease-in-out placeholder:text-[#808080] focus:border-slate-200 focus:ring-0 focus:ring-white dark:border-transparent dark:bg-darkmode-800 dark:placeholder:text-slate-500/80 ',
                props.formInputSize == 'sm' && 'px-2 py-1.5 text-xs',
                props.formInputSize == 'lg' && 'px-4 py-1.5 text-lg',
                props.rounded && 'rounded-full',
                formInline && 'flex-1',
                inputGroup &&
                    'z-10 rounded-none first:rounded-l last:rounded-r [&:not(:first-child)]:border-l-transparent',
                props.className,
            ])}
        />
    )
})

export default FormInput
