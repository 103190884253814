import { notFound } from 'next/navigation'
import { getRequestConfig } from 'next-intl/server'
import { createSharedPathnamesNavigation } from 'next-intl/navigation'

export type Locale = 'en' | 'de'

// app supported locales
export const locales = ['en', 'de'] as const

// set app default locale
export const defaultLocale = 'en'

// set locale prefix in URL
export const localePrefix = 'always'

export const { Link, redirect, usePathname, useRouter } =
    createSharedPathnamesNavigation({
        locales,
        localePrefix,
    })

export default getRequestConfig(async ({ locale }) => {
    // Validate that the incoming `locale` parameter is valid
    if (!locales.includes(locale as any)) notFound()

    return {
        messages: (await import(`@/messages/${locale}.json`)).default,
    }
})
